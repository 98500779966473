<template lang="pug">
  v-card
    v-card-title Повторная печать стикера.
    v-card-text
      v-form
        v-autocomplete(
          label="Точка осмотра"
          v-model="hostId"
          :items="filteredHostnames"
          item-value="id"
          item-text="hostname"
          :error-messages="getValidationErrors('hostId')"
          outlined
          dense
        )
        v-row
          v-col(cols="6")
            v-btn(
              @click="onCancel"
              outlined
              block
              :disabled="$root.isDisabled('print-sticker')"
            ) Отмена
          v-col(cols="6")
            v-btn(
              @click="onSubmit"
              block
              depressed
              color="primary"
              :loading="$root.isLoading('print-sticker')"
            ) Печать
</template>

<script>
import api from '@/api';
import mixins from '@/utils/mixins';
import { validator } from '@/utils/validation';
import { required } from 'vuelidate/lib/validators';

const validate = validator({
  hostId: { required },
});

export default mixins(validate).extend({
  name: 'PrintStickerModal',
  props: {
    hostnames: {
      type: Array,
      default: () => [],
      required: true,
    },
    inspectionId: {
      type: Number,
      required: true,
    },
    organizationId: {
      type: Number,
      required: true,
    },
    hostnameId: {
      type: [Number, String],
      required: true,
    },
  },
  watch: {
    hostnameId: {
      immediate: true,
      handler(value) {
        this.hostId = value;
      },
    },
  },
  data() {
    return {
      hostId: null,
    };
  },
  computed: {
    filteredHostnames() {
      let hosts = [];

      if (this.organizationId) {
        hosts = this.hostnames.filter(item => {
          return this.organizationId === item.organization.id;
        });
      }

      return hosts;
    },
  },
  methods: {
    onCancel() {
      this.reset();
      this.$emit('cancel');
    },
    async onSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      await api('inspections')
        .printSticker(this.inspectionId, this.hostId)
        .then(data => {
          this.reset();
          this.$emit('success', data);
          this.$notify({
            group: 'note',
            type: 'info',
            title: `Стикер отправлен на повторную печать для медосмотра #${this.inspectionId}`,
            text: '',
          });
        })
        .catch(error => {
          this.$emit('error', error);
          this.$notify({
            group: 'error',
            type: 'info',
            title: 'Ошибка повторной печати стикера',
            text: '',
          });
        });
    },
    reset() {
      this.hostId = null;
      this.$v.$reset();
    },
  },
});
</script>
