<template lang="pug">
  div
    keep-alive
      router-view

    v-dialog(
      v-if="isShowenPrintStickerModal"
      v-model="isShowenPrintStickerModal"
      max-width="500"
    )
      print-sticker(
        :hostnames="$store.getters.hostnames"
        :inspectionId="printStickerProps.inspectionId"
        :organizationId="printStickerProps.organizationId"
        :hostnameId="printStickerProps.hostnameId"
        @success="isShowenPrintStickerModal = false"
        @cancel="isShowenPrintStickerModal = false"
      )

</template>

<script>
import PrintSticker from '@/views/inspections/controls/PrintSticker';
import eventBus from '@/plugins/eventBus';

export default {
  components: { PrintSticker },

  data() {
    return {
      isShowenPrintStickerModal: false,
      printStickerProps: {},
    };
  },

  mounted() {
    eventBus.$on('showenPrintStickerModal', props => {
      this.printStickerProps = props;
      this.isShowenPrintStickerModal = true;
    });
  },
};
</script>
